<template>
  <div>
    <ListBoxNewOrder :order="this.order" :otherOrders="this.othersOrders" />
  </div>
</template>

<script>
import ListBoxNewOrder from '@/components/ListBoxNewOrder.vue';
export default {
  components: {
    ListBoxNewOrder,
  },
};
</script>
